<template>
  <div style="position: relative">
    <div class="btn-container" v-if="activeName === '1'">
      <el-button size="small" type="primary" @click="addQuestion">添加问题</el-button>
    </div>
    <el-tabs class="big-el-tabs" v-model="activeName" type="card" @tab-click="changeTab">
      <el-tab-pane label="问题列表" name="1" style="height: 100%">
        <el-tabs class="inner-tabs" tabPosition="left" v-model="activeQuestion" style="height: 100%"
                 @tab-click="changeTab">
          <el-tab-pane :label="'问题' + (index + 1)" :name="index + ''" v-for="(item,index) in questionList" :key="index">
            <el-form label-position="left" label-width="80px" class="left_10 common-form" :ref="'form' + index"
                     :name="'form' + index"
                     :model="item" :rules="rules">
              <el-form-item label="问题：" prop="question">
                <el-input :disabled="creatingOne !== index" class="small-input" v-model="item.question"
                          placeholder="请输入问题"/>
              </el-form-item>
              <el-form-item label="答案：" prop="answer" class="not_required">
                <div :id="'editor' + index" class="editor"></div>
              </el-form-item>
            </el-form>
            <div style="text-align: right">
              <el-button type="primary" style="padding: 12px 30px" :disabled="creatingOne !== index || item.deleteLoading"
                         :loading="item.loading" @click="save('form' + index,item)">保存
              </el-button>
              <el-button size="small" style="padding: 12px 30px" type="primary" @click="deleteQuestion(item)" :disabled="item.loading" :loading="item.deleteLoading">删除问题</el-button>
            </div>

          </el-tab-pane>
        </el-tabs>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import E from "wangeditor"

export default {
  name: "Question",
  data() {
    return {
      activeName: '1',
      form: {
        question: '',
        answer: '',
      },
      questionList: [
        {
          question: '',
          answer: '',
          editor: null,
          index: 0
        },
      ],
      rules: {
        question: [
          {required: true, message: '请输入问题', trigger: 'blur'}
        ],
        // answer: [
        //   {required: true, message: '请输入答案', trigger: 'blur'}
        // ]
      },
      activeQuestion: '0', //页面正在展示（操作）的问题
      creating: true,  //是否有正在创建的问题
      creatingOne: 0  //正在创建的问题下标
    }
  },
  methods: {
    changeTab() {

    },
    addQuestion() {
      if (this.creating) {
        this.$message.error("正在添加新问题，请勿重复添加");
        return
      }
      this.creating = true;
      this.questionList.push({
        question: '',
        answer: '',
        editor: null
      })
      this.creatingOne = this.questionList.length - 1;
      this.$nextTick(() => {
        this.createEditor(this.questionList.length - 1)
        this.activeQuestion = (this.questionList.length - 1) + '';
      })
      this.$forceUpdate()
    },
    deleteQuestion(item) {
      this.$confirm('确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        item.deleteLoading = true;
        this.$forceUpdate();
        //删除原有数据
        if (this.creatingOne !== Number(this.activeQuestion)) {
          this.$axios.post("/sys-api/device-api/deletedCustomerService",{id:item.id},(res) => {
            if(res.code === '100'){
              this.$message.success("删除问题成功");
              this.getQuestion();
            }
          })
          return
        }
        //删除新增的数据
        this.creatingOne = -1;
        this.creating = false;
        item.editor.destroy();
        if (this.questionList.length > 1) {
          this.questionList.splice(Number(this.activeQuestion), 1);
          if (this.activeQuestion === '0') this.activeQuestion = '1';
          else this.activeQuestion = (Number(this.activeQuestion) - 1) + '';
        } else {
          this.resetEditor()
        }

      })
    },
    createEditor(index) {
      this.questionList[index].editor = new E('#editor' + index)
      this.questionList[index].editor.create();
    },
    resetEditor() {
      this.questionList = [
        {
          question: '',
          answer: '',
          editor: null,
          index: 0
        },
      ]
      this.creating = true;
      this.creatingOne = 0;
      this.$nextTick(() => {
        this.createEditor(0)
        this.activeQuestion = '0';
      })
    },
    getQuestion(isInit) {
      //非初始化
      if(!isInit){
        this.questionList.forEach((item) => {
          item.editor.destroy()
        })
      }
      this.$axios.get("/sys-api/device-api/listCustomerService", {}, (res) => {
        if (res.code === '100') {
          if(res.data.length > 0){
            this.creating = false;
            this.creatingOne = -1;
            this.activeQuestion = '0';
            this.questionList = res.data;
            this.$nextTick(() => {
              this.questionList.forEach((item, index) => {
                this.createEditor(index);
                item.editor.txt.html(item.answer);
                item.editor.disable()
              })
            })

          }else {
            this.resetEditor();
          }
        }else this.resetEditor();
      })
    },
    save(formName, item) {
      this.$refs[formName][0].validate((valid) => {
        if (valid) {
          item.loading = true;
          this.$forceUpdate();
          let formData = {
            question: item.question,
            answer: item.editor.txt.html()
          }
          this.$axios.post("/sys-api/device-api/insertCustomerService", formData, (res) => {
            if (res.code === '100') {
              this.$message.success("添加问题成功");
              this.getQuestion();
            }
          })
        }
      })
    }
  },
  mounted() {
    this.getQuestion(true);
  }
}
</script>

<style scoped lang="scss">
.el-tabs {
  padding: 0 10px;
  height: calc(100% - 20px);
}

.big-el-tabs {
  padding: 10px;
}

.small-input {
  //width: 300px;
}

.big-el-tabs ::v-deep .el-tabs__content {
  height: calc(100% - 40px - 16px);
  overflow: auto;
}

.btn-container {
  position: absolute;
  top: 13px;
  right: 20px;
  z-index: 2;
}
</style>
